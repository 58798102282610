/* 

red #EA735B
blue #6782C6
yellow #EDC25A
green #35BCB2

*/

body {
  background-color: #000;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#ThreeFrame {
  height: 430px;
  overflow: hidden;
  position: relative;
  z-index: -1;

  div {
    position: absolute;
    bottom: 0;
  }

  &.open {
    height: 100vh;
    // transition: height 0.4s;
  }

  &.closed {
    height: 60vh;

    //transition: height 0.3s;
  }
}

#Menu {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100vw;

  &.open {
    height: 50vh;
    transition: height 0.4s;
  }
  &.closed {
    height: 10vh;
    transition: height 0.3s;
  }

  .upper {
    height: 10vh;
    display: flex;
    flex-direction: row;
  }

  .logo {
    /*height: 10vh;*/
    width: 33vw;
  }

  .lower {
    height: 40vh;
    width: 100vw;
    background-color: rgb(58, 58, 58);
  }

  .panel {
    display: inline-block;
    width: 100vw;
    height: 100vh;
  }

  .slider {
    transition: transform 0.6s;
    transform: translateX(0px);
    width: 400vw;
  }

  .lower.red .slider {
    transition: transform 0.6s;
    transform: translateX(0vw);
  }

  .lower.green .slider {
    transition: transform 0.6s;
    transform: translateX(-100vw);
  }

  .lower.yellow .slider {
    transition: transform 0.6s;
    transform: translateX(-200vw);
  }

  .lower.blue .slider {
    transition: transform 0.6s;
    transform: translateX(-300vw);
  }
}

.red {
  background: #ea735b;
  border-color: #ea735b;
}
.green {
  background: #35bcb2;
  border-color: #35bcb2;
}
.yellow {
  background: #edc25a;
  border-color: #edc25a;
}
.blue {
  background: #6782c6;
  border-color: #6782c6;
}

/* #Menu .lower.red {
  background: #EA735B;
}
#Menu .lower.green {
  background: #35BCB2;
}
#Menu .lower.yellow {
  background: #EDC25A;
}
#Menu .lower.blue {
  background: #6782C6;
} */

.button {
  margin-left: 2vw;
  width: 13vw;
  height: 8vh;
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 0vh;
  transition: border-bottom-width 0.2s;
  color: white;

  &.selected {
    border-bottom-width: 2vh;
    transition: border-bottom-width 0.2s;

    img {
      transition: opacity 0.2s;
      opacity: 1;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;

  img {
    position: absolute;
    right: 1vw;
    top: 1vh;
    transition: opacity 0.2s;
    opacity: 0;
  }
}
