body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}
*{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}